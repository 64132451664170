import Api from '@/plugins/api'

export default (superclass) => class extends superclass {

  load(data = false) {
    if (typeof this.loadables !== 'function')
      throw this.name + " does not implement [ loadables ] method";

    if (data)
      data = Array.isArray(data) ? data : [ data ];
    else
      data = Object.keys(this.loadables())

    data.forEach(loadable => {
      const url = this.loadableUrl(loadable)

      Api.get(url).then(data => {
        const model = data.data

        if (loadable == 'this')
          this.update(model)
        else {
          const serialized = this.serialize()
          serialized[loadable] = model
          this.update(serialized)
        }
      })
    });
  }

  loadableUrl(loadable) {
    let url = this.loadables()[loadable]
    const regx = /{.*?}/gi
    const parseParams = regx.exec(url)

    if (parseParams)
      parseParams.forEach(param => {
        const varName = param.replace('{', '').replace('}','')
        url = url.replace(param, this[varName])
      })
    return url
  }

}

