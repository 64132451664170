<template lang="pug">
  .view.view-register
    v-btn.btn-close(v-if="showSuccessMessage" fab small dark style="z-index:9999; top:5px; position:absolute; right:20px " @click="$store.commit('SUCCESS_MESSAGE_OFF')") X
    v-col.success(v-if="showSuccessMessage" id="status-message" style="color:#fff; flex-grow:0; height:50px; top:0px; position:absolute; background-color:#8fad94 !important; border-color:#8fad94 !important;") {{successMessage}}
    v-sheet(elevation="1" dark)
      .background-accent
      .title Edit Account
        v-icon ic-person

      FilePond.avatar(
        name="photo"
        ref="pond"
        labelIdle="Drop your photo..."
        accepted-file-types="image/*"
        allowRevert="true"
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth="200"
        imageResizeTargetHeight="200"
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center bottom"
        styleProgressIndicatorPosition="right bottom"
        styleButtonRemoveItemPosition="left bottom"
        styleButtonProcessItemPosition="right bottom"
        :onpreparefile="onInitFile"
        @removefile="onRemoveFile",
      )
      .avatar-error(v-if="showPhotoError") {{ errors.photo }}


      v-text-field.mt-8(name="first_name" label="First name" :error-messages="errors.firstname" v-model="firstname" rounded filled)
      v-text-field(name="last_name" label="Last name" :error-messages="errors.lastname" v-model="lastname" rounded filled)

      v-text-field(name="email" label="Email Address" :error-messages="errors.email" v-model="email" rounded filled)
      
      v-text-field(name="new_password" label="New Password" type="password" :error-messages="errors.new_password" v-model="new_password" rounded filled)
      v-text-field(name="new_password_confirmation" label="Repeat New Password" type="password" :error-messages="errors.new_password_confirmation" v-model="new_password_confirmation" rounded filled)

      v-text-field(name="password" label="Current Password" type="password" :error-messages="errors.password" v-model="password" rounded filled)
      v-select(:items="timezones" label="Timezone" rounded filled v-model="timezone")


      v-btn.submit.mt-5(color="#35543a" depressed=true hide-details x-large
        @click="register"
        :loading="inProgress"
      ) Update

</template>

<script>
import FormMixin from '@/mixins/FormMixin'


import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import store from "../store";


export default {

  components: {
    FilePond: vueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginImageCrop,
      FilePondPluginImageResize,
      FilePondPluginImageTransform,
      FilePondPluginImageEdit
    )
  },

  mixins: [
    FormMixin('userAccount')
  ],

  data() {
    return {
    }
  },

  computed: {
    showPhotoError() {
      return this.errors.photo && this.errors.photo.length > 0
    },

    inProgress() {
      return this.formState.in_progress
    },
    timezones() {
      return this.$store.state.timezones
    },
    showSuccessMessage() {
      return this.$store.state.successMessageShow
    },

    successMessage() {
      return this.$store.state.successMessage
    },

  },

  methods: {
    register() {
      this.formDispatch('UPDATE')
    },

  enterDown(event) {
      if (event.keyCode === 13)
        this.register()
    },

    onInitFile(file, output) {
      this.photo = output
      this.formCommit('SET_ERROR', { field: 'photo', message: [] })
    },

    onRemoveFile() {
      this.photo = null
    },
  },



  mounted() {
    this.formDispatch('LOAD_USER')
    .then(() => {
      this.$refs.pond.addFile(this.photo)
    })
    window.addEventListener("keypress", this.enterDown);
  },

  destroyed() {
    window.removeEventListener("keypress", this.enterDown);
  }
}
</script>