<template lang="pug">
  v-app
    v-main(:class="mainClasses")
      v-container(:fluid="fluid" style="padding:0px;")
        top-bar
        loader(v-if="showLoader")
        router-view(v-else)
        footer-bar
</template>

<script>

import TopBar from '@/components/navigation/TopBar'
import FooterBar from '@/components/navigation/FooterBar'
import Loader from '@/components/common/Loader'

export default {
  name: 'App',
  components: { TopBar, FooterBar, Loader },

  data: () => ({
    //
    loaderOverlay: true
  }),

  computed: {
    fluid() {
      return !this.$vuetify.breakpoint.xl
    },
    mainClasses() {
      return {
        desktop: !this.fluid
      }
    },
    showLoader() {
      return this.$store.state.auth.is_preloading || this.$store.state.loader
    },

  }
};
</script>
