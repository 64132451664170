import Store from '@/store'
export default function generateFormFields(formName, fieldName) {
  const fields = Store.state.forms[formName][fieldName]
  const generated = {}
  Object.keys(fields).forEach((fieldName) => {
    generated[fieldName] = {
      get() {
        return fields[fieldName]
      },
      set(value) {
        return this.formDispatch('UPDATE_FIELD', {
          field: fieldName,
          value
        })
      }
    }
  })
  return generated
}