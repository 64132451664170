import { mix } from 'mixwith'

import BaseModel from '@abs/model'
import UserMethods from './user_methods'
import PaymentMethods from '../payments/methods'
import UserSchema from './schema'
import modelLoader from '@abs/modelLoader'

class User extends mix(BaseModel).with(UserMethods, UserSchema, modelLoader, PaymentMethods) {
  static create(data) {
    return new this(this.parseWithDefaults(data))
  }

  loadables() {
    return {
      masterclass: 'get-my-masterclasses'
    }
  }

  getMasterclasses() {
    if (!this.masterclass.length)
      this.load()

    return this.masterclass
  }
}

export default User
