import login from '@/modules/auth/login/form'
import registration from '@/modules/auth/registration/form'
import userAccount from '@/modules/models/user/formAccount'

export default {
  namespaced: true,
  modules: {
    login,
    registration,
    userAccount
  }
}