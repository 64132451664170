
/**
 * Base mutations of the form
 */
 export default (mutations) => Object.assign({
  UPDATE_FIELD(state, { field, value }) {
    state.fields[field] = value
    const fe = state.validationErrors[field]
    if (fe && fe.length) {
      state.validationErrors[field] = []
    }
  },

  UPDATE_MODEL_FIELD(state, { field, value }) {
    state.model[field] = value
  },

  UPDATE_STATE(state, { field, value }) {
    state[field] = value
  },

  SET_ERROR(state, {field, message}) {
    state.validationErrors[field] = message
    state.validationErrors = Object.assign({}, state.validationErrors)
  },

  SET_ERRORS(state, errors){
    state.validationErrors = errors
  },

  MERGE_ERRORS(state, errors){
    state.validationErrors = Object.assign({}, state.validationErrors, errors)
  },

  PUSH_INTO_FIELD(state, { field_name, value }) {
    let field = state.fields[field_name]
    field.push(value)
    state.fields[field_name] = field
  },

  SET_FIELDS(state, fields) {
    state.model = fields
    _.forOwn(state.fields, function(value, key) {
      if (fields[key])
        state.fields[key] = fields[key]
    })
  },

  CLEAR_FIELDS(state, fields) {
    const copy = JSON.parse(JSON.stringify(state.__initial_state.fields))

    for (let key in copy) {
      state.fields[key] = copy[key]
    }
    state.model = {}
  }

}, mutations)