import actions from './actions'
import mutations from './mutations'
import state from './state'


/*
import registration from './registration'
import passwordReset from './passwordReset'
import promo from './promo'
import getCompanyName from './getCompanyName'
*/
export default {
  namespaced: true,

  actions,
  mutations,
  state,
  modules: {
    //login,
    /*
    registration,
    passwordReset,
    promo,
    getCompanyName
    */
  }
}
