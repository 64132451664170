<template lang="pug">
    .view.view-masterclass.fh

      v-btn.btn-close(v-if="showSuccessMessage" fab small dark style="z-index:9999; top:15px " @click="$store.commit('SUCCESS_MESSAGE_OFF')") X
      v-col.success(v-if="showSuccessMessage" style="color:#fff; flex-grow:0; height:50px; background-color:#8fad94 !important; border-color:#8fad94 !important;") {{successMessage}}

      v-btn.btn-close(v-if="showWatch" fab small dark style="z-index:9999" @click="$store.commit('WATCH_OFF')") X
      video.fh(v-if="showWatch" id="video" controls controlsList="noremoteplayback nodownload" autoplay ref="vid")
        source(:src="watchUrl")

      iframe.fh(v-if="showConference" :src="confeferenceUrl" style="border:0px" allow="camera;microphone;display-capture")

      v-row(v-if="!showWatch && !showConference" no-gutters)
        stripe-checkout(ref="stripe" :pk="stripePK" :sessionId="stripeSessionId")
        v-col.body(cols="12" md="6")
          .section.hp
            v-row
              v-col(
                v-for="c in masterclasses"
                :key="c.id"
                cols="12" md="6")
                .item(:class="{ active: isItemAtive(c) }" @click="activateItem(c)")
                  v-img(:src="c.image")
                  .status-wrap
                    .triangle(:class="statusClasses(c)")

        v-col.body(v-if="selectedClass" cols="12" md="6" class="d-none d-sm-none d-md-flex")
          master-preview.section(v-model="selectedClass" @joinConference="joinConference" @watchMasterclass="watchMasterclass" @stripeInit="stripeInit" ref="mpreview")


      v-dialog(
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
        class="bg-main"
      )
        v-toolbar(
          dark
          color="bgDark"
          absolute
        )
          v-spacer
          v-btn.mr-1(
            icon
            dark
            @click="dialog = false"
          )
            v-icon mdi-close

        v-sheet.fh(color="bgMain" style="padding-top: 60px;")
          master-preview(v-model="selectedClass" @joinConference="joinConference" @watchMasterclass="watchMasterclass" @stripeInit="stripeInit" ref="mpreview")
      

</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import MasterPreview from '@/components/MasterPreview'
import dateFormatter from '@/scripts/dateFormatter'
//import moment from 'moment'
import moment from 'moment-timezone'

export default {
  components: { StripeCheckout, MasterPreview },
  data:function() {
    return {
      selectedClass: null,
      stripeSessionId: null,
      masterclasses: [],
      confeferenceUrl: null,
      dialog: false,
    }
  },

  computed: {
    stripePK() {
      return process.env.VUE_APP_STRIPE_PK
    },

    showConference() {
      return this.$store.state.showConference
    },

    showWatch() {
      return this.$store.state.showWatch
    },

    showSuccessMessage() {
      return this.$store.state.successMessageShow
    },

    successMessage() {
      return this.$store.state.successMessage
    },

    eventInFuture() {
      if (!this.selectedClass)
        return false

      return dateFormatter.isInFuture(this.selectedClass.end_time, this.$user().timezone)
    }

  },

  methods:{
    loadMasterclasses() {
      this.$api.get('search-masterclasses')
      .then(res => {
        this.masterclasses = res.data.results
        if (this.masterclasses.length > 0)
          this.setActiveMasterClass(this.masterclasses[0])
      })
    },

    stripeInit(value) {
      this.stripeSessionId = value//res.data.id
      this.$refs.stripe.redirectToCheckout();
    },

    statusClasses(masterclass) {

      if (masterclass.user_status != 'registered')
        return {}

      const endTimeInFuture = dateFormatter.isInFuture(masterclass.end_time, this.$user().timezone)

      return {
        passed: !endTimeInFuture,
        upcomming: endTimeInFuture,
        err: false
      }
    },

    isItemAtive(item) {
      return this.selectedClass.id == item.id
    },

    activateItem(item) {
      this.setActiveMasterClass(item)
      this.$refs.mpreview.updateCounter()
      this.$refs.mpreview.showVideo(false)

      if (this.$vuetify.breakpoint.smAndDown)
        this.dialog = true
    },

    setActiveMasterClass(item) {
      this.selectedClass = { ...item, ...{}}
    },

    joinConference() {
      if (this.selectedClass.participantData.iframe_url) {
        this.confeferenceUrl = this.selectedClass.participantData.iframe_url
        this.$store.commit('CONFERENCE_ON')
      }
      else {
        this.$api.get('get-masterclass?id=' + this.selectedClass.id)
          .then(res => {
            this.confeferenceUrl = res.data.participantData.iframe_url
            this.$store.commit('CONFERENCE_ON')
          })
      }
    },

    watchMasterclass() {
      if (this.selectedClass.watch_url) {
        this.watchUrl = this.selectedClass.watch_url
        this.$store.commit('WATCH_ON')
      }
      else {
        this.$api.get('get-masterclass?id=' + this.selectedClass.id)
            .then(res => {
              this.watchUrl = res.data.watch_url
              this.$store.commit('WATCH_ON')
            })
      }
    },

    proceedLiveEvent(params = false, query = false) {

      var confurl = 'https://d3cqo6io8ac4hk.cloudfront.net/' + params.moderator + '?'
      if (query && query['aId'] && query['eId'] && query['name']) {
        confurl += 'aId=' + query['aId'] + '&eId=' + query['eId'] + '&name=' + encodeURIComponent(query['name'])
        this.confeferenceUrl = confurl
        this.$store.commit('CONFERENCE_ON')
      }

      //console.log('proceed liveEvent:', this.confeferenceUrl, params, this.$route)
    },
  },

  watch: {
    '$vuetify.breakpoint.smAndDown': function(newVal, oldVal) {
      if (!newVal)
        this.dialog = false
    }
  },

  mounted() {
    if (this.$route.name == 'LiveModerator' || this.$route.name == 'LiveTalent')
      this.proceedLiveEvent(this.$route.params, this.$route.query)
    else
      this.$store.commit('CONFERENCE_OFF')

    this.loadMasterclasses()
  }
}
</script>