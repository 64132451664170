<template lang="pug">
  v-menu(
    v-if="user"
    bottom
    min-width="200px"
    rounded
    offset-y
    nudge-left="150"
    nudge-bottom="15"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        icon
        x-large
        v-on="on"
      )
        v-avatar(
          color="brown"
          size="48"
        )
          img(
            :src="user.photo"
            alt=""
          )
      

    v-card
      v-list-item-content(class="justify-center")
        div(class="mx-auto text-center")
          h3 {{ user.fullname }}
          p(class="text-caption mt-1") {{ user.email }}
          v-divider(class="my-3")
          v-btn(
            depressed
            rounded
            text
            to="/account"
          )  Edit Account
          v-divider(class="my-3")
          v-btn(
            depressed
            rounded
            text
            @click="logout"
          )  Logout
</template>


<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userPhoto() {
      return this.user ? this.user.photo : null
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/LOGOUT')
    }
  }
}
</script>