/**
 * Base state of the Form
 */

export default (state) => {

  let baseState = {
    validationErrors: !state.fields ? {} : mapErrors(state.fields),
    fields: {},
    model: {},
    is_edit: false
  }

  baseState.__initial_state = JSON.parse(JSON.stringify(Object.assign(baseState, state)))

  return baseState
}

function mapErrors(fields) {
  let mapped = Object.assign({}, fields)
  Object.keys(mapped).map((key, index) => {
    mapped[key] = []
  })
  return mapped
}