<template lang="pug">
    .view.view-login
      v-sheet(elevation="1" dark)
        .background-accent
        .title Login
          v-icon ic-auth-key
        .small.mt-2 Please login to your account

        v-text-field.mt-8(
          name="email"
          label="Email Address"
          :error-messages="errors.email"
          v-model="email"
          type="email"
          filled
          rounded
        )
        v-text-field.mt-5(
          name="password"
          label="Password"
          type="password"
          :error-messages="errors.password"
          v-model="password"
          filled
          rounded
        )
        v-btn.submit.mt-5(
          color="#35543a"
          depressed=true
          hide-details
          x-large
          @click="login"
          :loading="isLoading"
        ) Login
      .bottom.text-center
        | Don`t have an account? 
        router-link.primary--text(to="register") Register Now
        div.pt-2
        //-div.py-2 or
        router-link.primary--text(to="reset") Forgot Password?
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [
    FormMixin('login')
  ],

  computed: {
    isLoading() {
      return this.$store.state.auth.is_preloading
    }
  },

  methods: {
    login() {
      this.formDispatch('LOGIN')
        .catch(() => {})
    },

    enterDown(event) {
      if (event.keyCode == 13)
        this.login()
    }
  },

  mounted() {
    window.addEventListener("keypress", this.enterDown);

    if (process.env.NODE_ENV === 'development') {
      this.email = 'user@creatstep.com'
      this.password = 'user123'
    }
  },

  destroyed() {
    window.removeEventListener("keypress", this.enterDown);
  },
}
</script>