<template lang="pug">
    membership(v-if="showMembership")
    landing(v-else)
</template>

<script>

import Landing from './Landing'
import Masterclass from './Masterclass'
import Membership from './Membership'

export default {
  components: { Landing, Masterclass, Membership },

  computed: {
    showMasterclass() {
      return this.loggedIn && this.registrationCompleted
    },
    showMembership() {
      return this.loggedIn && !this.registrationCompleted
    },

    user() {
      return this.$store.state.auth.user
    },

    registrationCompleted() {

      const v = this.user ? this.user.registration_completed : false
      return v
    },

    loggedIn() {
      return this.$store.state.auth.is_logged_in
    }
  }
}
</script>