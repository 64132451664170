<template lang="pug">
    v-app-bar(
      app
      color="bgDark"
      dark
      height="60"
      class="px-10"
    )
      div(class="d-flex align-center logo-wrap" @click="logoClick")
        v-img(
          alt="Creatstep Logo"
          class="shrink mr-2"
          contain
          src="@/assets/images/logo-color.svg"
          transition="scale-transition"
          width="40"
        )

        v-img(
          alt="Vuetify Name"
          class="shrink mt-1"
          contain
          min-width="100"
          src="@/assets/images/creatstep-name.png"
          width="100"
        )

      v-spacer
      .d-flex(v-if="loggedIn")
        v-btn(text rounded  class="d-none d-md-flex" outlined color="primary" to="/master-classes" @click="masterClick") Master classes
        v-btn(text rounded  class="d-none d-md-flex mx-2") Social

      .d-flex(v-else)
        v-btn(text rounded  class="d-none d-md-flex" @click="scrollTo('workflow')") How does it work?
        v-btn(text rounded  class="d-none d-md-flex" @click="scrollTo('tools')") Tools
        v-btn(text rounded  class="d-none d-md-flex" @click="scrollTo('pricing')") Pricing
        v-btn(text rounded  class="d-none d-md-flex main-gradient mx-2" to="/register") Join Now
        v-btn(text rounded  class="d-none d-md-flex" to="/login") Login

      top-profile

      v-menu(bottom left)
        template(v-slot:activator="{ on, attrs }")
          v-app-bar-nav-icon(v-bind="attrs" v-on="on" class="d-sm-flex d-md-none")

        v-list.mobile-menu(class="d-sm-flex d-md-none")
          v-list-item(v-if="loggedIn" to="/master-classes" @click="masterClick")
            v-list-item-title master classes
          v-list-item(v-if="loggedIn")
            v-list-item-title social
          
          v-list-item(v-if="!loggedIn" @click="scrollTo('workflow')")
            v-list-item-title How does it work?
          v-list-item(v-if="!loggedIn" @click="scrollTo('tools')")
            v-list-item-title Tools
          v-list-item(v-if="!loggedIn" @click="scrollTo('pricing')")
            v-list-item-title Pricing
          v-list-item.register(v-if="!loggedIn" to="register" class="primary--text")
            v-list-item-title Join Now
          v-list-item(v-if="!loggedIn" to="/login")
            v-list-item-title Login

</template>

<script>
import TopProfile from './TopProfile.vue'
export default {
  components: {
    TopProfile
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.is_logged_in
    }
  },
  methods: {
    logoClick() {
      if (this.$route.name == 'Home')
        window.location = window.location.origin
      else
        this.$router.push({ name: 'Home' })
    },

    masterClick() {
      this.$store.commit('CONFERENCE_OFF')
    },

    scrollTo(id) {

      var scroll = function(elementId) {
        const el = document.getElementById(elementId)
        window.scrollTo(0, el.offsetTop)
      }


      if (this.$route.name != 'Home') {
        this.$router.push('/')
          .then(() => {
            setTimeout(() => scroll(id), 100)
          })
      }
      else
        scroll(id)
    }
  }
}
</script>


<style lang="scss">
.v-app-bar {
  .logo-wrap:hover {
    cursor: pointer;
  }
}
.mobile-menu {
  flex-direction: column;
  .v-list-item:hover { background: rgba($cl-bg-light, 0.1); cursor:pointer; }
  .v-list-item.register {
    &:hover {
      background: rgba($cl-primary, 0.2);
    }
  }
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).register {
  color: $cl-primary !important;
}
</style>