import { mix } from 'mixwith'

import Schema from './schema'
import BaseModel from '@abs/model'
import modelLoader from '@abs/modelLoader'
import SerializesData from '@abs/concerns/serializes-data'

class Masterclass extends mix(BaseModel).with(Schema, SerializesData) {
  static create(data) {
    return new this(this.parseWithDefaults(data))
  }

}

export default Masterclass