String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

Array.prototype.pushEach = function(items) {
  items.forEach((e) => {
    this.push(e)
  })
  return this
}

Array.prototype.first = function() {
  return this[0] ?? null
}

Array.prototype.remove = function() {
  var what, a = arguments, L = a.length, ax;
  while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
      }
  }
  return this;
};

Array.prototype.filterDeep = function() {
  let inner = arguments[0],
      callback = arguments[1],
      r = false

  return this.filter(function(item) {

    if (callback) {
      item = callback(item, this) // sends current item and parent!
    }

    if (Array.isArray(item[inner]) && item[inner].length > 0)
      item[inner] = item[inner].filterDeep(inner, callback, item)

    return item
  })
}

Array.prototype.mapDeep = function() {
  let inner = arguments[0],
      callback = arguments[1],
      r = false

  return this.map(function(item) {

    if (callback) {
      item = callback(item, this) // sends current item and parent!
    }

    if (Array.isArray(item[inner]) && item[inner].length > 0)
      item[inner] = item[inner].mapDeep(inner, callback, item)

    return item
  })
}

Array.prototype.eachDeep = function() {
  let inner = arguments[0],
      callback = arguments[1],
      r = false

  return this.map(function(item) {

    if (callback) {
      callback(item, this) // sends current item and parent!
    }

    if (Array.isArray(item[inner]) && item[inner].length > 0)
      item[inner].eachDeep(inner, callback, item)

  })
}


Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
}

//ARRAY sortBy custom function
/* 
  Use it like so:
    array.sortBy(function(o){ return o.date });
  If your date is not directly comparable, make a comparable date out of it, e.g.
    array.sortBy(function(o){ return new Date( o.date ) });
  You can also use this to sort by multiple criteria if you return an array of values:
    // Sort by date, then score (reversed), then name
    array.sortBy(function(o){ return [ o.date, -o.score, o.name ] };
*/
let defineSortBy = function() {
  if (typeof Object.defineProperty === 'function'){
    try{ Object.defineProperty(Array.prototype,'sortBy',{value:sb}); } catch(e) {
      //console.log(e)
    }
  }
  if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

  function sb(f){
    for (var x=this.length;x;){
      var o = this[--x];
      this[x] = [].concat(f.call(o,o,x),o);
    }
    this.sort(function(a,b){
      for (var i=0,len=a.length;i<len;++i){
        if (a[i]!=b[i]) return a[i]<b[i]?-1:1;
      }
      return 0;
    });
    for (var y=this.length;y;){
      this[--y]=this[y][this[y].length-1];
    }
    return this;
  }
};
defineSortBy()


