<template lang="pug">
  //-hello-world
  .view.view-landing
    .d-flex.fw(v-if="videoShow")
      video.fw(id="video" controls autoplay controlsList="noremoteplayback nodownload" ref="vid")
        source(:src="videoSource")

    v-carousel(
      v-else
      hide-delimiters
      height="auto"
      @change="scrollTo('app')"
      v-model="carouselModel"
    )
      v-carousel-item.hover(
        v-for="item in items"
        :key="item.id"
        :src="item.src"
        @click="showVideo($event, item)"
      )

        v-btn.play-video(
          dark
          color="main-gradient"
          rounded
        )
          v-icon.pr-2 mdi-play
          span.pr-2 Play Video

    hooper(:settings="hooperSettings" ref="hooper")
      slide(
        v-for="(item, i) in items"
        :key="i"
      )
        v-img(:src="item.src" @click="showImage($event, item)")
      hooper-navigation(slot="hooper-addons" color="white")

    v-sheet.section(id="workflow" color="#0c0c0c" dark)
      .explanation
        | CreatStep breaks down communication barriers with an innovative, user-friendly platform to connect people passionate about all aspects of the creative arts, at all levels of experience.
      v-row
        v-col.item(cols="12" sm="6")
          h1 Learn, Create & Collaborate
          .description
            | Learn, create & collaborate with a self-selected network of like-minded artists, technical and business professionals—industry movers, makers and behind-the-scenes shakers.

        v-col(cols="12" sm="6")
          h1 Meet People You Want
          .description
            | Meet only the people you truly want to connect with and who are looking to find you, determined by your profile and interests.

        v-col(cols="12" sm="6")
          h1 Gain Invaluable Insight
          .description
            | Gain invaluable insights and advice via interactive classes and workshops with the Arts and entertainment industry’s most highly regarded luminaries.

        v-col(cols="12" sm="6")
          h1 Search Bios And Portolios
          .description
            | Search bios and portfolios to come up with the right colleagues to move a current project forward or conceive future ones.

        v-col(cols="12" sm="6")
          h1 Gain Exposure
          .description
            | Get feedback and noticed by influential industry pros by sharing the fruits of your labor online in a supportive space.

        v-col(cols="12" sm="6")
          h1 Start Working
          .description
            | Turn concepts into careers and transform inspiration into art.


    v-sheet.section(id="tools" color="#1c1c1c" dark)
      h1.text-center Powerful Tools
      .menu
        v-btn(
          v-for="item in toolItems"
          :key="item.id"
          :outlined="item.active ? false : true"
          rounded
          :color="item.active ? 'primary' : 'white'"
          @click="setActiveToolItem(item)"
        ) {{ item.label }}

      v-row.mt-10
        v-col(cols="12" sm="8")
          v-img.flex-grow-2(:src="appUrl + activeToolItem.src")
        v-col.px-5(cols="12" sm="4")
          .d-flex.flex-column.flex-grow-1
            h3.mb-5 {{ activeToolItem.title }}
            .desc {{ activeToolItem.description }}

      //-.d-flex.mt-10
        v-img.flex-grow-2(src="http://creatstep.4savi.com/images/meet1.png")
        .d-flex.flex-column.section.flex-grow-1
          h3.mb-5 Get noticed
          .desc With its sophisticated algorithm of shared interests and experience, CreatStep helps you find like-minded colleagues quickly and easily.

    v-sheet.section(id="token" color="#0c0c0c" dark)
      h1.text-center(style="padding-bottom:40px") CREATSTEP token is on its way
      .description.text-center
        | CREAT is the utility token of the Creatstep platform, which gives users direct access into the creative arts, technology and business industry through its interactive Master classes and its soon-to-be launched social networking portal. CREAT users benefit from reduced prices compared to paying by Fiat, which in turn incentivizes more widespread adoption of the token on Creatstep. The idea behind CREAT grew out of the desire to combine the worlds of creative arts, technology, and business with a DeFi system that enables decentralized payment methods and allows Creatstep members to use the token to crowdfund projects easily and safely.
      v-spacer
      .description.text-center(style="padding-top:30px")
        | Contract address:
        .text-center(style="font-size:12px") 0x42A344D69CeE95A9B59460d6d020C3D243D679D1
        v-spacer
        v-spacer
        v-btn(style="top:30px; margin-bottom:30px" color="primary" large href="https://creatstep.io/whitepaper/CREATSTEP_WP2_2.pdf" target="_blank" to="") Whitepaper
        v-spacer

    v-sheet.section(id="token" color="#1c1c1c" dark)
      h1.text-center(style="padding-bottom:30px") Roadmap
      .timeline
        h2.timeline__item.timeline__item--year 2023 Q3
        .timeline__item
          h3.timeline__title
          | &#8226; CREAT Token TGE event

        h2.timeline__item.timeline__item--year 2023 Q4
        .timeline__item
          h3.timeline__title
          | &#8226; CREATSTEP live event connecting Celebrities and CREAT holders
          v-spacer
          | &#8226; CREATSTEP registration opens

        h2.timeline__item.timeline__item--year 2024 Q1
        .timeline__item
          h3.timeline__title
          | &#8226; CREATSTEP live masterclass event

        h2.timeline__item.timeline__item--year 2024 Q3
        .timeline__item
          h3.timeline__title
          | &#8226; CREATSTEP interactive masterclass platform opening

        h2.timeline__item.timeline__item--year 2024 Q4
        .timeline__item
          h3.timeline__title
          | &#8226; CREATSTEP social networking beta release


    v-sheet.section(id="pricing" color="#0c0c0c" dark)
      h1.text-center Pricing Plans
      v-row.prices
        v-col(
          v-for="plan in memberships"
          :key="plan.id"
          cols="12" sm="6" md="3")
          v-card.item
            .name {{ plan.title }}
            .price {{ plan.price }} $
            .includes
              .prop(
                v-for="(p,i) in plan.properties"
                :key="i"
              ) {{ p }}


            v-btn.main-gradient(v-if="!loggedIn" rounded to="register" :loading="plan.active") Join Now


</template>

<script>
  import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
  import 'hooper/dist/hooper.css';
  //import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',

    components: {
      Hooper, Slide, HooperNavigation
      //VueSlickCarousel
      //HelloWorld,
    },

    data () {
      return {
        carouselModel: 0,
        videoShow: false,
        videoSource: false,
        hooperSettings: {
          itemsToShow: 4,
          infiniteScroll: true,
          //centerMode: true
        },
        // max 6 items..
        items: [
          {
            id: 0,
            src: require('@/assets/images/masters/goldstein.jpg'),
            video: require('@/assets/videos/2.mp4')
          },
          {
            id: 1,
            src: require('@/assets/images/masters/fionnula-_flanagan.jpg'),
            video: require('@/assets/videos/7.mp4')
          },
          {
            id: 2,
            src: require('@/assets/images/masters/bernard_hiller.jpg'),
            video: require('@/assets/videos/1.mp4')
          },
          /*{
            id: 3,
            src: require('@/assets/images/masters/siedah_garret.jpg'),
            video: '/videos/siedah_garrett.mp4'
          },*/
          {
            id: 3,
            src: require('@/assets/images/masters/thom_noble.jpg'),
            video: require('@/assets/videos/3.mp4')
          },
          {
            id: 4,
            src: require('@/assets/images/masters/tom_donahue.jpg'),
            video: require('@/assets/videos/6.mp4')
          },
        ],

        toolItems: [
          {
            id: 1,
            src: '/images/meet1.png',
            label: 'Meet',
            title: 'Get Noticed',
            description: 'With its sophisticated algorithm of shared interests and experience, CreatStep helps you find like-minded colleagues quickly and easily.',
            active: true
          },
          {
            id: 2,
            src: '/images/learn6.png',
            label: 'Learn',
            title: 'Get Inspired',
            description: 'Find a master, take a class in real time, consult useful resources, meet and discuss your work in a private tutorial suited to your needs.',
            active: false
          },
          {
            id: 3,
            src: '/images/pitch.png',
            label: 'Pitch',
            title: 'Get your team',
            description: 'Hire a team for your project or find a team in need of your precise expertise.',
            active: false
          },
          {
            id: 4,
            src: '/images/build.png',
            label: 'Build',
            title: 'Get It Done',
            description: 'Customize an online workspace for team sharing and discussion.',
            active: false
          },
          {
            id: 5,
            src: '/images/show.png',
            label: 'Show',
            title: 'Get The Word Out',
            description: 'Show off your work, invite feedback, catch up on what others are up to.',
            active: false
          },
        ]
      }
    },

    computed: {
      appUrl() {
        return process.env.VUE_APP_URL
      },
      memberships() {
        return this.$store.state.memberships
      },

      activeToolItem() {
        return this.toolItems.filter(x => x.active).first()
      },

      loggedIn() {
        return this.$store.state.auth.is_logged_in
      }
    },

    methods: {
      showImage(event, item) {
        this.videoShow = false
        this.carouselModel = item.id
        this.scrollTo('app')
      },

      showVideo(event, item) {
        if (this.$refs.hooper.isSliding)
          event.preventDefault()
        else {
          this.videoSource = item.video
          this.videoShow = true
          if (this.$refs.vid)
            this.$refs.vid.load()
          this.scrollTo('app')
        }
      },

      updateHooper() {
        this.$refs.hooper.updateWidth()
      },

      setActiveToolItem(item) {
        this.toolItems = this.toolItems.map(x => {
          x.active = (x.id == item.id) ? true : false
          return x
        })
      },

      scrollTo(id) {
        const el = document.getElementById(id)
        el.scrollIntoView()
      }
    },

    created() {
      window.addEventListener("resize", this.updateHooper);
    },
    destroyed() {
      window.removeEventListener("resize", this.updateHooper);
    },
  }
</script>
