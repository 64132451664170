import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8fad94',
        secondary: '#93549e',
        accent: '#b64e9a',
        //--
        error: '#f993af',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',

        bgLight: '#333333',
        bgDark: '#1e1e1e',
        bgMain: '#262626',

        logoRed: '#ce90a1',
        logoYellow: '#c6c690',
        logoGreen: '#719a9e',
        logoBlue: '#8da0bc'
        //--profession types

        /*arts: '#c28f9c',
        tech: '#8ea2bc',
        business: '#729b9f',
        education: '#bcbc8d'*/

      }
    }
  },
});
