<template lang="pug">
    .view.view-login
      v-sheet(v-if="success" elevation="1" dark)
        .background-accent
        .title Check your e-mail
          v-icon ic-auth-key
        .small.mt-2 Please check your e-mail address we have sent you reset link.

      v-sheet(v-else elevation="1" dark)
        .background-accent
        .title Reset Password
          v-icon ic-auth-key
        .small.mt-2 Please enter your e-mail address

        v-text-field.mt-8(
          name="email"
          label="Email Address"
          v-model="email"
          type="email"
          :error-messages="errors"
          filled
          rounded
        )
        v-btn.submit.mt-5(
          color="#35543a"
          depressed=true
          hide-details
          x-large
          @click="recover"
          :loading="isLoading"
        ) Recover
      //-
        .bottom
          | Don`t have an account? 
          router-link.primary--text(to="register") 

      
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [
    //FormMixin('login')
  ],

  data: function() {
    return {
      email: null,
      errors: [],
      success: false
    }
  },

  computed: {
    isLoading() {
      return false //this.$store.state.auth.is_preloading
    }
  },

  methods: {
    recover() {
      this.$api.post('auth/forgot-password', {
        email: this.email
      })
      .then((response) => {
        this.success = true
      })
      .catch((response) => {
        this.errors.push('Email is invalid.')
      })

      //this.formDispatch('LOGIN')
    },

    enterDown(event) {
      if (event.keyCode == 13)
        this.recover()
    }
  },

  mounted() {
    window.addEventListener("keypress", this.enterDown);
  },

  destroyed() {
    window.removeEventListener("keypress", this.enterDown);
  },
}
</script>