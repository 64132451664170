import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/styles/main.scss'
window._ = require('lodash')

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

/* Http Client */
import VueResource from 'vue-resource'
Vue.use(VueResource)

/* API plugin */
import Api from '@/plugins/api'
Vue.use(Api)

/* Auth plugin */
import Auth from '@/plugins/auth'
Vue.use(Auth)

import '@/scripts/prototypes'
import preloader from '@/scripts/preloader'
preloader()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
