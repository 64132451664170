import Vue from 'vue'

export default {
  UPDATE_ACCESS_TOKEN(state, accessToken) {
    state.access_token = accessToken
  },

  UPDATE_ACCESS_TOKEN_COOKIE(state) {
    Vue.$cookies.set('access_token', state.access_token, "1m");
  },

  SET_AUTHENTICATED(state, isAuthenticated) {
    state.is_logged_in = isAuthenticated
  },

  SET_PRELOADING(state, isPreloading) {
    state.is_logged_in = true
    state.is_preloading = isPreloading
  },

  SET_LOADING(state, isLoading) {
    state.is_logged_in = false
    state.is_preloading = isLoading
  },

  SET_ACCOUNT(state, account) {
    state.account = account
  },

  SET_USER(state, user) {
    Vue.set(state, 'user', user)
    Vue.auth.setUser(user)
  },

  UPDATE_USER(state) {
    Vue.set(state, 'user', state.user)
    Vue.auth.setUser(state.user)
  },

  /*
  SET_ACCEPTING_INVITATION(state, isAcceptingInvitation) {
    state.isAcceptingInvitation = isAcceptingInvitation
  },

  SET_LOCKED(state, isLocked) {
    state.isLocked = isLocked
  },

  SET_ACCOUNT_PERMISSIONS(state, data) {
    state.account.permissibles = data
  },
 */
  SET_LOADED(state, isLoaded) {
    state.is_logged_in = true
    state.is_loaded = true
    state.is_preloading = false
  },

  REDIRECTING(state, to) {
    state.is_redirecting = true
    state.redirecting_to = to
  },

  SET_WAS_REDIRECTED(state, wasRedirected) {
    state.was_redirected = wasRedirected
  },

  /**
   * Resets main state
   */
  CLEAR_ALL_DATA(state) {
    const copy = JSON.parse(JSON.stringify(state.__initial_state))

    for (let key in copy) {
      state[key] = copy[key]
    }

    Vue.$cookies.remove('access_token')
  }
}
