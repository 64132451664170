<template lang="pug">
  .section.hp.fw.master-preview
    div(v-if="value.video")
      video(v-if="videoShow" width="100%" controls disablepictureinpicture autoplay controlsList="noremoteplayback nodownload nofullscreen" ref="vid")
          source(:src="value.video")

      v-carousel(
        v-else
        hide-delimiters
        height="auto"
        :show-arrows="false"
      )
        v-carousel-item.hover(
          :key="1"
          :src="value.image"
          @click="showVideo(true)"
        )

          v-btn.play-video(
            dark
            color="main-gradient"
            rounded
            style="position: absolute; bottom: 22%; left: 3%;"
          )
            v-icon.pr-2 mdi-play
            span.pr-2 Play Video

    v-img.rounded(v-else :src="value.image" width="100%")

    h1.pt-5 {{ value.title }}
    .description.text-justify {{ value.about }}

    //-v-sheet.event-details.px-5.py-4.mt-5(color="bgDark" dark)
      .d-flex
        h2 Event details
        v-spacer
        h3.error--text Cancelled

      p.times
        | Start time: 2021-05-13 15:00 PM
        br
        | End time: 2021-05-13 16:00 PM

      p.sec-text.text-justify
        | This event is cancelled due to the fact that Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.

    v-sheet.event-details.px-5.py-4.mt-5(v-if="eventInFuture || value.user_status !== 'soldout'" color="bgDark" dark)
      .d-flex
        h2 Event details

      p.times
        | Start time: {{ startTime }}
        br
        | Registration deadline: {{ registrationDeadlineTime }}
        br
        | End time: {{ endTime }}

      .separator
      .price-wrap
        .titles.flex-column
          .title Real-time
          .description You can watch and interact with master
        v-spacer
        .actions
          .registered(v-if="value.user_status == 'registered'")
            .d-flex.flex-column.align-center(v-if="counter")
              h4 Starts In
              time-counter(
                ref="counter"
                :time="value.start_time"
                @start="counter = false"
                @count="counter = true"
              )
            v-btn.price(v-else class="main-gradient" rounded large @click="$emit('joinConference')") JOIN

          v-btn.price(v-else-if="value.price == '0'" rounded class="main-gradient" large @click="registerForFree()" :loading="loadingRealtime")
            .final_price {{ value.price }} $
            s {{ value.regular_price }} $

          v-btn.price(v-else-if="value.price < value.regular_price" rounded class="main-gradient" large @click="buyRealtime()" :loading="loadingRealtime")
            .final_price {{ value.price }} $
            s {{ value.regular_price }} $

          v-btn.price(v-else rounded class="main-gradient" large @click="buyRealtime()" :loading="loadingRealtime") {{ value.price }} $

    v-sheet.event-details.px-5.py-4.mt-5(v-else color="bgDark" dark)
      .d-flex
        h2 Event details
        v-spacer
        h3.primary--text(v-if="value.user_status == 'soldout'") Sold out
        h3.primary--text(v-else) Over

      p.times
        | Start time: {{ startTime }}
        br
        | Registration deadline: {{ registrationDeadlineTime }}
        br
        | End time: {{ endTime }}

      .separator(v-if="value.publish_record == '1'")
      .price-wrap(v-if="value.publish_record == '1'")
        .titles.flex-column
          .title Watch
          .description You can watch the video record
        v-spacer
        .actions
          .registered(v-if="value.user_watch_status == 'registered'")
            v-btn.price(class="main-gradient" rounded large @click="$emit('watchMasterclass')") WATCH

          v-btn.price(v-else rounded color="#22bb55" large @click="buyWatch()" :loading="loadingRealtime") {{ value.watch_price }} $
</template>

<script>

import TimeCounter from '@/components/common/TimeCounter'
import dateFormatter from '@/scripts/dateFormatter'

export default {
  props: {
    value: {
      type: Object,
      require: true
    }
  },

  components: {
    TimeCounter
  },

  data:function() {
    return {
      loadingRealtime: false,
      counter: true,
      conferenceUrl: null,
      videoShow: false,
    }
  },

  computed: {
    userTimezone() {
      return this.$user().timezone
    },

    startTime() {
      return  this.value.start_time_human
    },
    registrationDeadlineTime() {
      return  this.value.registration_deadline_human
    },
    endTime() {
      return this.value.end_time_human
    },

    eventInFuture() {
      if (!this.value)
        return false

      return dateFormatter.isInFuture(this.value.end_time, this.userTimezone)
    }
  },

  methods: {

    buyRealtime() {
      this.loadingRealtime = true
      this.$user().buyPlan(this.value.id, 'masterclass', '/master-classes')
        .then(resp => {
            this.$emit('stripeInit', resp.data.id)
            this.loadingRealtime = false
        })
    },

    registerForFree() {
      this.loadingRealtime = true
      this.$user().buyFreeMasterclass(this.value.id)
    },

    buyWatch() {
      this.loadingRealtime = true
      this.$user().buyPlan(this.value.id, 'watch', '/master-classes')
          .then(resp => {
            this.$emit('stripeInit', resp.data.id)
            this.loadingRealtime = false
          })
    },

    updateCounter() {
      if (this.$refs.counter)
        this.$nextTick().then(this.$refs.counter.updateClock)
    },

    showVideo(show) {
        this.videoShow = show
    },
  },

  mounted() {
    
  }
}
</script>