import { FormState, FormGetters, FormMutations, FormActions } from '@/modules/abstracts/form'
import Api from '@/plugins/api'
import router from "../../../router";

const state = FormState({
  fields: {
    email: '',
    password: '',
    // site_address: ''
  }
})
const mutations = FormMutations()
const getters = FormGetters({

  VALIDATES: (state) => {
    return {
      email: ['required', 'Please enter your email.'],
      password: ['required', 'Please enter your password.']
    }
  },

})
const actions = FormActions({

  async LOGIN({ dispatch, commit, state}) {

    await dispatch('VALIDATE_FIELDS')

    // throw new Error('handled Error')
    commit('auth/SET_LOADING', true, { root: true })

    Api.post('auth/login', {
      email: state.fields.email,
      password: state.fields.password
    })
    .then((response) => {
      console.log('LOGIN RESPONSE', response)
      const accessToken = response.data.token
      const data = response.data.user_data
      dispatch('auth/AUTHENTICATE', { accessToken, data}, { root: true })
      router.push({ path: '/master-classes' })
    })
    .catch((response) => {
      commit('auth/SET_LOADING', false, { root: true })
      commit('SET_ERRORS', {
        email: [''],
        password: ['Wrong Email or Password. Try again.']
      })
      throw response
    })
  }


})

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
  // getters
}
