import router from '@/router'
//import { PROJECTS } from '@/router/routes'
import Api from '@/plugins/api'
import AuthUser from '@models/user/user'

export default {

  AUTHENTICATE({ commit, dispatch }, {accessToken, data, redirect = false}) {
    commit('UPDATE_ACCESS_TOKEN', accessToken)
    commit('UPDATE_ACCESS_TOKEN_COOKIE')

    dispatch('INIT', data)
    commit('SET_LOADED')

    if (redirect)
      router.push({ path: '/' })
  },

  INIT({ dispatch, commit, state }, data = null) {
    console.log('INIT', data)

    // Use preloaded data if possible, load everything if not
    if (!data)
      return

    /**
    * data is user data
    */
    dispatch('SET_USER', data)

    /* Real time updates */
    //Echo.connect()
  },

  SET_USER({ commit }, user) {
    commit('SET_USER', AuthUser.create(user))
  },

  LOGOUT({ commit, state }, { redirect = true } = {}) {
    // state.is_logged_in = false
    Api.post('auth/logout')
    commit('CLEAR_ALL_DATA')

    // localStorage.removeItem('state.auth')
    // Echo.disconnect()

    if (redirect) {
      router.push({ path: '/login' }).catch(() => {})
    }
  },

  LOAD({ dispatch, commit, state }) {
    commit('SET_PRELOADING', true)
    Api.get('me')
      .then((response) => {

        console.log('load me resp', response)
        const accessToken = state.access_token
        const data = response.data
        dispatch('AUTHENTICATE', { accessToken, data })
      })
      .catch(() => {
        commit('SET_LOADING', false)
        console.log('error on action auth/load')
        //dispatch('LOGOUT')
      })
  },

  //can refreshe something...
  REFRESH({ commit, state }) {}
}