<template lang="pug">
  .view.view-membership.fh
    .section
      v-sheet.section.section-welcome(dark color="bgDark")
        p
          span.welcome Welcome to Creatstep, 
          | an innovative, comprehensive platform designed to connect people
          | passionate and involved in all aspects of the creative arts, at all levels of experience. Opening
          | up a new world of opportunity to learn, create and collaborate, CreatStep puts a network of
          | like-minded artists, technical and business professionals—industry movers, makers and behind-
          | the-scenes shakers—right at your fingertips. Meet only the people you truly want to connect
          | with and who are looking to find you, linked by your mutual interests. Gain invaluable one-on-
          | one advice via interactive master classes and workshops conducted by the most highly
          | regarded arts and entertainment industry luminaries. Easily search through an extensive
          | collection of bios and portfolios to come up with the perfect colleagues to move a current
          | project forward or conceive future ones. And once you do, share it online through a supportive
          | space that nurtures ideas, helps turn concepts into careers and transforms inspiration into art.


    v-sheet.section(id="pricing" dark v-if="isActivation")
      h1.text-center Activate your Membership
      h4.text-center Check your email you should be received an activation code
      .d-flex.justify-center.pt-10
        v-text-field(
          label="Activation code"
          v-model="code"
          rounded filled
          style="max-width: 300px"
          :error="codeError"
        )
        v-btn.main-gradient.ml-5(
          rounded @click="activateMembership"
          x-large
          :loading="codeActivating"
        ) Activate
      .d-flex.justify-center.pt-2
        a.hover.primary--text(@click="resendActivation") Resend activation code

    v-sheet.section.section-plans(id="pricing" color="black" dark v-else)
      stripe-checkout(ref="stripe" :pk="stripePK" :sessionId="stripeSessionId")

      h1.text-center Preorder your Membership
      v-row.prices
        v-col(
          v-for="plan in memberships"
          :key="plan.id"
          cols="12" sm="6" md="3")
          v-card.item
            .name {{ plan.title }}
            .price {{ plan.price }} $
            .includes
              .prop(
                v-for="(p,i) in plan.properties"
                :key="i"
              ) {{ p }}

            v-btn.main-gradient(rounded @click="buyClick(plan)" :loading="plan.active") Buy Now

      //-v-btn.main-gradient(rounded @click="buyMembership()") Buy Now
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  components: { StripeCheckout },
  data: function() {
    return {
      code: null,
      codeActivating: false,
      stripeSessionId: null
    }
  },
  computed: {
    stripePK() {
      return process.env.VUE_APP_STRIPE_PK
    },

    isActivation() {
      return this.$user() && this.$user().redirect == 'registration_activate'
    },

    memberships() {
      return this.$store.state.memberships
    },

    codeError() {
      return this.$store.state.membershipCodeError
    },

    loggedIn() {
      return this.$store.state.auth.is_logged_in
    }
  },

  methods: {
    buyClick(plan) {
      this.$store.commit('MEMBERSHIP_ACTIVATE', plan)
      if (plan.price == 0)
        this.buyFree(plan)
      else
        this.buyMembership(plan)
    },

    buyMembership(plan = false) {
      this.$user().buyPlan(plan.id, 'membership')
        .then(res => {
          this.stripeSessionId = res.data.id
          this.$refs.stripe.redirectToCheckout();
        })
    },

    buyFree(plan) {
      this.$user().buyFree(plan.id)
    },

    resendActivation() {
      this.$user().resendActivationCode()
    },

    activateMembership() {
      this.codeActivating = true
      this.$store.state.membershipCodeError = false
      this.$user().activatePlan(this.code)
        .then(() => {
          this.codeActivating = false
          this.$router.push('/master-classes')
        })
        .catch(() => {
          this.codeActivating = false
          this.$store.state.membershipCodeError = true
        })
    }
  }
}
</script>