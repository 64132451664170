import api from '@/plugins/api'
import store from '@/store'

const PaymentMethods = (superclass) => class extends superclass {

  buyFree(id) {
    return api.post('payments/save', {
      membership_id: id
    })
    .then(() => {
      store.dispatch('auth/LOAD')
      store.commit('MEMBERSHIPS_DEACTIVATE')
    })
    .catch(() => {
      alert('Invalid plan. Try again later.')
      store.commit('MEMBERSHIPS_DEACTIVATE')
    })
  }

  buyFreeMasterclass(id) {
    return api.post('payments/select-free-masterclass', {
      masterclass_id: id
    })
      .then(() => {
        store.dispatch('auth/LOAD')
        store.commit('SUCCESS_MESSAGE_ON', 'You have successfully registered to the masterclass.')
      })
      .catch(() => {
        alert('Something went wrong. Try again later.')
        //store.commit('MEMBERSHIPS_DEACTIVATE')
      })
  }

  buyPlan(id, type, return_url = null) {
    return api.post('payment-session', {
      id: id,
      type: type,
      return_url: return_url ? window.location.origin + return_url : window.location.origin,
    })
  }

  activatePlan(code) {
    store.commit('LOADER_SHOW')
    return api.post('activate-code', {
      'code': code
    })
    .then(() => {
      store.dispatch('auth/LOAD')
    })
    .finally(() => {
      store.commit('LOADER_HIDE')
    })
  }

  resendActivationCode() {
    store.commit('LOADER_SHOW')
    return api.post('payments/resend-code')
    .finally(() => {
      store.commit('LOADER_HIDE')
    })
  }

}

export default PaymentMethods