<template lang="pug">
  v-btn(icon @click="$emit('click')")
    v-icon(large) {{ icon }}
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    }
  }
}
</script>