
import MasterclassModel from '@models/masterclass/model'

const UserSchema = (superclass) => class extends superclass {

  static defaults() {
    return {
      masterclass: []
    }
  }


  static parse(data) {
    const d = {}

    d.firstname = data.general.firstName
    d.lastname = data.general.surName
    d.photo = data.general.photo
    d.email = data.general.email
    d.registration_completed = data.registration_completed
    d.redirect = data.redirect
    d.fullname = d.firstname + ' ' + d.lastname
    d.timezone = data.general.timezone

    d.masterclass = data.masterclass.map(x => MasterclassModel.create(x))
    return d
  }

  serialize(options = {}) {
    return {
      //...super.serialize(options),
      general: {
        firstName: this.firstname,
        surName: this.lastname,
        photo: this.photo,
        email: this.email,
      },
      registration_completed: this.registration_completed,
      redirect: this.redirect,
      masterclass: this.masterclass,
      timezone: this.timezone
    }
  }
}

export default UserSchema
