<!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/6236f4191ffac05b1d7f7756/1fujaj12l';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
})();
</script>
<!--End of Tawk.to Script-->
<template lang="pug">
  v-footer(color="#1c1c1c" dark)
    v-img(
      alt="Creatstep Logo"
      class="shrink mr-2"
      contain
      src="@/assets/images/logo-white.svg"
      transition="scale-transition"
      width="30"
    )
    .footer-text Copyright © 2023 CreatStep. All rights reserved.
    v-spacer(style="flex-grow:0.8!important")
    a(href="https://twitter.com/creatstepio" target="_blank") Twitter
    a(href="https://t.me/creatstepofficial" target="_blank") Telegram
    a(href="/terms-of-use" target="_blank") Terms of Service
    a(href="mailto:info@creatstep.io") Contact
</template>
