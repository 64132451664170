<template lang="pug">
  .view.view-login
      v-sheet(v-if="success" elevation="1" dark)
        .background-accent
        .title Password changed successfully
          v-icon ic-auth-key
        .small.mt-2 You can now login with your new password.
        v-btn.submit.mt-5(
          color="#35543a"
          depressed=true
          hide-details
          x-large
          to="login"
        ) Go to Login

      v-sheet(v-else elevation="1" dark)
        .background-accent
        .title Reset Password
          v-icon ic-auth-key
        .small.mt-2 Please enter your new password

        v-text-field.mt-8(
          name="email"
          label="Email Address"
          :error-messages="errors.email"
          v-model="email"
          type="email"
          filled
          rounded
        )

        v-text-field(name="password" label="Password" type="password" :error-messages="errors.password" v-model="password" rounded filled)
        v-text-field(name="password_confirmation" label="Repeat Password" type="password" :error-messages="errors.password_confirmation" v-model="password_confirmation" rounded filled)

        v-btn.submit.mt-5(
          color="#35543a"
          depressed=true
          hide-details
          x-large
          @click="recover"
        ) Set New Password
      .bottom
        | Don`t have an account? 
        router-link.primary--text(to="register") Register Now
</template>

<script>
export default {
  data: function()  {
    return {
      success: false,
      password:null,
      password_confirmation: null,
      email: null,
      token: null,

      errors: {
        password:null,
        password_confirmation: null,
        email: null
      }
    }
  },

  methods: {
    recover() {
      this.$api.post('auth/reset-password', {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        token: this.token
      })
      .then((response) => {
        this.success = true
      })
      .catch((response) => {
        this.errors = response.data.data
      })
    }
  },

  mounted() {
    if (this.$route.query.token)
      this.token = this.$route.query.token
  }
}
</script>