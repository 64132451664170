let state = {
  // state flags
  is_logged_in: false,

  // are we preloading base data, when user refreshes page when he/she is logged in?
  is_preloading: false,
  // isLocked: false,
  is_loaded: false,
  // isAcceptingInvitation: false,

  // are we currently redirecting user?
  is_redirecting: false,

  // where are we redirecting the user.
  redirecting_to: null,

  // did user came here from redirection?
  was_redirected: false,

  // Access token for API
  access_token: null,

  /**
   * Update this instance ONLY through mutations.
   * The only reason I put auth user object here
   * is to keep reactivity working.
   *
   * @var {User}  Currently authenticated user data
   */
  user: null,

  account: {

  }
}

state.__initial_state = JSON.parse(JSON.stringify(state))

export default state
