<template lang="pug">
  v-dialog(
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  )
    v-card
      v-toolbar(
        dark
        color="primary"
        style="border-radius: 0px;"
        elevation="0"
        dense
      )
        v-icon.logo(@click="$emit('close')") ic-logo-black

        v-toolbar-title.ml-5 Settings

        v-spacer
        v-toolbar-items
          v-btn(icon dark @click="$emit('close')")
            v-icon mdi-close

      slot
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false }
  }
}
</script>