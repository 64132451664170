import * as validators from './validators'
//import _ from 'lodash/object'

export default (actions) => {
  return Object.assign({

    DELETE_ARRFIELD_ITEM(ctx, { field, itemData }) {
      const filtered = ctx.state.fields[field].filter((el) => {
        return itemData !== el
      })
      ctx.commit('UPDATE_FIELD', {
        field: field,
        value: filtered
      })
    },

    UPDATE_FIELD({dispatch, commit}, inputField) {
      commit('UPDATE_FIELD', inputField)
      dispatch('VALIDATE_FIELD', inputField)
    },

    UPDATE_MODEL_FIELD({dispatch, commit}, inputField) {
      commit('UPDATE_MODEL_FIELD', inputField)
    },

    VALIDATE_FIELD(ctx, {field, value}) {
      const validatable = ctx.getters.VALIDATABLES[field]

      // if validatable is not set for the field, doing nothing / everything is ok!
      if (!validatable)
        return

      const vldbs = extractValidatables(validatable)
      vldbs.forEach((vldb) => {

        if (!_.has(validators, vldb.type)) {
          console.warn(`VALIDATOR TYPE [${vldb.type}] IS NOT DEFINED: in modules/documents/form/validators.js`)
          return
        }

        if (validators[vldb.type](value, vldb.params, ctx.state.fields))
          ctx.commit('SET_ERROR', {
            field: field,
            message: vldb.message
          })
      })
    },

    async VALIDATE_FIELDS(ctx, only = false) {
      ctx.commit('SET_ERRORS', {})
      const fieldNames = only ? only : Object.keys(ctx.state.fields)

      fieldNames.forEach((fieldName) => {
        ctx.dispatch('VALIDATE_FIELD', {
          field: fieldName,
          value: ctx.state.fields[fieldName]
        })
      })

      return new Promise((resolve, reject) => {
        ctx.getters.HAS_ERRORS ? reject('rejecting with validation errors') : resolve()
      })
    },

  }, actions)
}


/*
RETURN exp:
[
  { type:"required", params:{}, message:'error message' },
  { type:"equals", params:['password'], message:'error message' },
  ....
  { type: .... }
]
*/
function extractValidatables(validatable) {
  const types = validatable[0].split('|')
  const errorMsgs = validatable[1]
  let ret = []

  types.forEach((type, index) => {
    const params = type.split(':')
    ret.push({
      type: params[0],
      params: params.length == 1 ? params.slice(1) : params.slice(-1),
      message: Array.isArray(errorMsgs) ? errorMsgs[index] : errorMsgs
    })
  })

  return ret
}