/**
 * Base Model for api models
 */
class Model {
  constructor(props) {
    this.fill(props)
    if (typeof this.init === 'function') {
      this.init()
    }
  }

  fill(props) {
    for (let key in props) {
      this[key] = props[key]
    }
  }

  update(data) {
    this.fill(
      this.constructor.parse(data)
    )
    return this
  }

  static parseWithDefaults(data) {
    if (typeof this.parse !== 'function' || typeof this.defaults !== 'function')
      throw this.name + " does not implement one of the static methods [defaults || parse]";

    // to secure undefined values on upcoming data
    return this.parse({ ...this.defaults(), ...data })
  }
}

export default Model
