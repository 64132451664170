
const MasterclassSchema = (superclass) => class extends superclass {

  static defaults() {
    return {
      uuid: null,
      name: null,
    }
  }

  static parse(data) {
    const d = {}

    /*d.uuid = data.uuid
    d.name = data.name
    d.details = data.details.map(x => ProjectDetailModel.create(x))
    d.active = data.active
    d.active_detail = d.details.filter(x => x.active).first() ?? d.details.first()

    if (d.active_detail)
      d.active_detail.setActive(true)
*/
    return d
  }

  serialize(options = {}) {
    return {
      //...super.serialize(options),
      /*uuid: this.uuid,
      name: this.name,
      details: this.details.map(item => item.serialize()),
      active: this.active,
      active_detail: this.active_detail
      */
    }
  }
}

export default MasterclassSchema
