<template lang="pug">
  .chip-list(:class="classes")
    h4.label(v-if="label") {{ label }}
    v-badge(
        v-for="i in listItems"
        :key="i.uuid"
        :content="i.messages"
        :value="itemMessages(i)"
        overlap
        color="none"
      )
        v-btn(
          :small="small"
          rounded
          :outlined="(!i.active && clickable)"
          :depressed="true"
          :color="i.color"
          @click="btnClick(i)"
        ) {{ i.name }}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    small: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    listItems() {
      const $this = this
      return this.list.map(function(item) {
        item.color = item.color ?? $this.color
        return item
      })
    },
    classes() {
      return {
        'expanded': this.expanded
      }
    }
  },

  methods: {
    itemMessages(item) {
      return item.messages ?? 0
    },

    btnClick(btn) {

      if (!this.clickable)
        return

      this.$emit('click', btn)
    }
  }
}
</script>