
export default (getters) => Object.assign({
  VALIDATES: () => {
    return {}
  },

  VALIDATABLES: (state, getters) => {
    return getters.VALIDATES
  },

  HAS_ERRORS: (state) => {
    let errors = 0
    Object.keys(state.validationErrors).forEach((e) => {
      // debugger
      if (typeof state.validationErrors[e] === 'string') errors += 1
      else errors += state.validationErrors[e].length
    })
    return errors > 0
  },

  IS_EDITING: (state) => {
    return state.is_edit
  },

  FIELDS_AS_FORM_DATA: (state) => {
    var formData = new FormData();
    Object.keys(state.fields).forEach(function(key) {
      if (state.fields[key])
        formData.append(key, state.fields[key])
    })
    return formData
  }
}, getters)