<template lang="pug">
  v-card.btn(flat :class="btnClasses" @click="$emit('input')" :disabled="disabled")
    .icon-bg(v-if="icon")
      v-icon(:color="color" :small="small") {{ icon }}
    label {{ label }}
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Default label'
    },
    icon: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white'
    },
    small: {
      type: Boolean,
      defalut: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnClasses: function() {
      return {
        'btn-flat': this.flat,
        'btn-small': this.small
      }
    }
  }
}
</script>
