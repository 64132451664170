export default {

  SET_MEMBERSHIPS(state, data) {
    state.memberships = data.map(x => {
      x.active = false
      return x
    })
  },

  MEMBERSHIP_ACTIVATE(state, plan) {
    state.memberships = state.memberships.map(x => {
      if (x.id == plan.id)
        x.active = true
      return x
    })

  },

  MEMBERSHIPS_DEACTIVATE(state) {
    state.memberships = state.memberships.map(x => {
      x.active = false
      return x
    })
  },

  SET_TIMEZONES(state, data) {
    var zones = []
    Object.keys(data).forEach(x => {
      zones.push({
        text: data[x],
        value: x
      })
    })
    state.timezones = zones
  },

  CONFERENCE_OFF(state) {
    state.showConference = false
  },

  CONFERENCE_ON(state) {
    state.showConference = true
  },

  WATCH_OFF(state) {
    state.showWatch = false
  },

  WATCH_ON(state) {
    state.showWatch = true
  },

  SUCCESS_MESSAGE_OFF(state) {
    state.successMessageShow = false
  },

  SUCCESS_MESSAGE_ON(state, message) {
    state.successMessageShow = true
    state.successMessage = message
  },

  LOADER_SHOW(state) {
    state.loader = true
  },

  LOADER_HIDE(state) {
    state.loader = false
  }
}