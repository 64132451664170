/**
 * Prepares given object for component injection as vue accepts
 * In particular it will insert new attributes [_props, _events] into each object / array item
 *
 * and then in VueComponent
 * v-bind = "_props"
 * v-on = "_events"
 */

export default function(obj) {
  if (!_.isArray(obj) && !_.isObject(obj))
    throw new Error('Vuetize only accepts [Object, Array]: you are sending ' + (typeof obj))

  return vuetizeObject(obj)
}

/**
 * Separates each item into (props & events)
 * and merges into itself
 */
function vuetizeObject(obj) {
  var keysToDelete = []
  var result = {}

  Object.keys(obj).forEach((key) => {

    // if obj is null we skipping and later
    // we will delete this property
    if (obj[key] === null) {
      keysToDelete.push(key)
      return
    }

    result[key] = { ...obj[key], ...separateItem(obj[key]) }

    // lets load component which will gonna be used later
    // obj[key]['component'] = getComponent(obj[key]['component'], obj[key]['vuetify'])
  })

  keysToDelete.forEach((prop) => { delete obj[prop] })

  return _.orderBy(result, 'order')
}

function getComponent(path, vuetify) {
  if (vuetify)
    return vuetify

  if (path)
    return require('@/components/' + path).default

  return null
}

/**
  Object separation into props & events
  if key starts with 'ev' - its event
  everything else - its a prop to bind
 */

function separateItem(obj) {

  let _props = {}
  let _events = {}

  Object.keys(obj).forEach((key) => {
    if (key.startsWith('ev')) {
      const k = key.replace('ev', '')
      _events[k] = obj[key]
    }
    else if (key !== 'component' && key !== 'vuetify')
      _props[key] = obj[key]
  })

  return { _props, _events }
}