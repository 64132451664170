import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import ResetPasswordEmail from '../views/auth/ResetPasswordEmail.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Register from '../views/auth/Register.vue'
import Account from '../views/Account.vue'
import Masterclass from '../views/Masterclass.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/live-event/:moderator', name: 'LiveModerator', component: Masterclass },
  { path: '/live-event/:talent', name: 'LiveTalent', component: Masterclass },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: isLoggedIn
  },
  { // email to send request
    path: '/reset',
    name: 'Reset',
    component: ResetPasswordEmail,
    beforeEnter: isLoggedIn
  },
  { // a form with fields...
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: isLoggedIn
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: isLoggedIn
  },

  // Authenticated
  {
    path: '/master-classes/:id?',
    name: 'MasterClasses',
    component: Masterclass
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isLoggedIn(to, from, next) {
  const auth = router.app.$options.store.state.auth
  if (auth.is_logged_in) {
    next({
      path: '/master-classes',
    })
  } else {
    next()
  }
}

export default router
//      https://creatstep.com/live-event/moderator?aId=352878b1-7f40-4877-9ac5-4ac2da6248ce&eId=LiveEvent-Ta3J&name=Arturas%20Saskinas
// ->
// https://d3cqo6io8ac4hk.cloudfront.net/moderator?aId=352878b1-7f40-4877-9ac5-4ac2da6248ce&eId=LiveEvent-Ta3J&name=Arturas%20Saskinas

