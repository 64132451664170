import Store from '@/store'
import ModMixin from './ModMixin'
//import PassivesMixin from './PassivesMixin'
import AbstractForm from '@/components/form/AbstractForm'
import vuetize from '@/scripts/vuetize'
//import Button from '@/components/common/buttons/Button'
import fieldGenerator from '@/components/form/fieldGenerator'

export default (formName) => {
  return {
    components: { parent: AbstractForm },
    mixins: [ ModMixin ],

    data: function() {
      return {
        modName: formName,
        validateFields: true,
        formBaseActions: {
          //spacer: { component: 'v-spacer', order: 50 },
          //cancel: { label: 'Cancel', icon: 'ic-close', evinput: this.formBaseActionCancel, component: Button, flat: true, small: true, color:'red', order: 98 },
          //save: { label: 'Save', icon: 'ic-done', evinput: this.formBaseActionSave, component: Button, flat: true, small: true, color:'primary', order: 99 },
        },
      }
    },

    computed: {

      formRef() {
        return (this.$refs.parent)
          ? this.$refs.parent.$refs.form
          : this.$refs.form
      },

      formBind() {
        return {
          formActions: vuetize(this._formActions),
          ref: 'parent',
        }
      },

      formActions() {
        return {}
      },

      _formActions() {
        return _.merge(this.formBaseActions, this.formActions)
      },

      errors() {
        return this.formState.validationErrors
      },

      modelData() {
        return this.formState.model
      },

      ...fieldGenerator(formName, 'fields')
    },

    methods: {
      formBaseActionCancel() {
        this.$emit('form-cancel')
      },
      formBaseActionSave() {
        if (this.validateFields && this.formRef.validate())
          this.formDispatch('VALIDATE_FIELDS').then(() => {
            this.formEmitData()
          })

        else if(!this.validateFields)
          this.formEmitData()
      },

      formEmitData() {
        const ev = (this.formGetter('IS_EDITING')) ? 'form-update' : 'form-create'
        this.$emit(ev, this.formState.fields)
      },
    }
  }
}

