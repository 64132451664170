<template lang="pug">
  v-form.form(ref="form")
    v-sheet(:elevation="formElevation")
      v-toolbar.content-pad(v-if="showToolbar" elevation="0" rounded v-bind="toolbarBinds")
        h4.form-title {{ title }}
        v-spacer
        toolbar-btn(icon="ic-close" @click="$emit('close')")

      .form-subtitle(v-if="showSubtitle") {{ subtitle }}

      slot

      .section.actions.mt-5
        slot(name="section-actions")
          component(
            v-for="(action, index) in formActions"
            :key="index"
            :is="action.component"
            v-on="action._events"
            v-bind="action._props"
          )


</template>

<script>
import ToolbarBtn from '@/components/common/buttons/ToolbarBtn'
export default {
  components: { ToolbarBtn},
  props: {
    formActions: {
      type: Array,
      default: () => { return [] }
    },
    title: { type: String },
    subtitle: { type: String },
    formElevation: { type: Number, default: 1 },
    showToolbar: { type: Boolean, default: true },
    showSubtitle: { type: Boolean, default: true },
    toolbarDark: { type: Boolean, default: true },
    toolbarTheme: { type: String, default: 'theme--gradient'}
  },

  computed: {
    toolbarBinds() {
      return {
        class: this.toolbarTheme,
        dark: this.toolbarDark
      }
    }
  }
}
</script>