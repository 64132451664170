import { FormState, FormGetters, FormMutations, FormActions } from '@/modules/abstracts/form'
import Api from '@/plugins/api'
import store from "../../../store";

const state = FormState({
  fields: {
    photo: null,
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    new_password: null,
    new_password_confirmation: null,
    country_id: '1',
    timezone: null,
  },
  in_progress: false,
  photo_blob: null,
})
const mutations = FormMutations()
const getters = FormGetters({

  VALIDATES: (state) => {
    return {
      photo: ['required', 'Please upload your photo.'],
      firstname: ['required', 'Please enter your first name.'],
      lastname: ['required', 'Please enter your last name.'],
      email: ['required', 'Please enter your email.'],
      password: ['required', 'Please enter your current password.'],
      timezone: ['required', 'Please select your timezone.'],
      //new_password_confirmation: ['equals:password', 'Passwords should match.'],
    }
  },

})
const actions = FormActions({

  async LOAD_USER({dispatch, commit, state, rootState}) {

    const user = rootState.auth.user
    
    state.fields.firstname = user.firstname
    state.fields.lastname = user.lastname
    state.fields.email = user.email
    state.fields.password = null
    state.fields.new_password = null
    state.fields.new_password_confirmation = null
    state.fields.timezone = user.timezone

    await fetch(user.photo)
    .then(r => r.blob())
    .then(blob => {
      state.photo_blob = blob
      state.fields.photo = blob
    })

  },

  async UPDATE({ dispatch, commit, state, rootState, getters }) {

    await dispatch('VALIDATE_FIELDS').catch(() => {
      throw new Error('Update form rejected [validationErrors]')
    })

    if (state.photo_blob.size == state.fields.photo.size)
      state.fields.photo = rootState.auth.user.photo

    state.in_progress = true
    Api.post('auth/update', getters.FIELDS_AS_FORM_DATA, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=reg'
      }
    })
    .then((response) => {
      console.log('response account udpate: ', response)
      const accessToken = response.data.token
      const data = response.data.user_data
      dispatch('auth/AUTHENTICATE', { accessToken, data, redirect: false }, { root: true })
      state.in_progress = false
      //workaround to reset photo
      state.fields.photo = state.photo_blob
      store.commit('SUCCESS_MESSAGE_ON', 'You have successfully updated your profile.')
     //scroll to the top.
      const el = document.getElementById('app')
      el.scrollIntoView()
    })
    .catch((response) => {
      //commit('auth/SET_LOADING', false, { root: true })
      commit('MERGE_ERRORS', response.body.data)
      state.in_progress = false
      //workaround to reset photo
      state.fields.photo = state.photo_blob
    })
  }
})
//const getters = RepositoryGetters()

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
  // getters
}
