<template lang="pug">
  .time-counter
    | {{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
</template>

<script>

import moment from 'moment-timezone'

export default {

  props: {
    time: {
      default: null
    }
  },

  data: function() {
    return {
      total: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null
    }
  },

  methods: {
    setTimeRemaining() {
      const cm = moment()
      const current_timestamp = cm.utc().valueOf()
      this.total = this.time * 1000 - current_timestamp

      this.seconds = Math.floor((this.total / 1000) % 60);
      this.minutes = Math.floor((this.total / 1000 / 60) % 60);
      this.hours = Math.floor((this.total / (1000 * 60 * 60)) % 24);
      this.days = Math.floor(this.total / (1000 * 60 * 60 * 24));
    },

    updateClock() {
      this.setTimeRemaining()

      const emit = (this.total <= 0) ? "start" : "count"
      this.$emit(emit)
    }
  },

  created() {
    this.timer = setInterval(this.updateClock, 1000);
    this.updateClock()
  },
  destroyed() {
    clearInterval(this.timer);
  },
}
</script>