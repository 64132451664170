import BaseFormState from './state'
import BaseFormGetters from './getters'
import BaseFormActions from './actions'
import BaseFormMutations from './mutations'


export const FormState = BaseFormState
export const FormActions = BaseFormActions
export const FormMutations = BaseFormMutations
export const FormGetters = BaseFormGetters
