import Api from '@/plugins/api'
import Vue from 'vue'
import store from '@/store'

export default () => {

  Api.get('get-memberships')
    .then(resp => {
      store.commit('SET_MEMBERSHIPS', resp.data)
    })

  Api.get('get-timezones')
    .then(resp => {
      store.commit('SET_TIMEZONES', resp.data)
    })

  const token = Vue.$cookies.get('access_token')
  if (!token)
    return

  store.commit('auth/UPDATE_ACCESS_TOKEN', token)
  store.dispatch('auth/LOAD')

}