import moment from 'moment-timezone'


const dateFormatter = {

  /*
  old method which worked on all browsers, parsed datestring '2020-01-02 18:00' into js date object
  getDate(dateString) {
    const dt = dateString.replaceAll('-', ' ').replaceAll(':', ' ').split(' ')
    return new Date(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5], 0)
  },
  */

  format(timestamp, timezone = false) {
    const format = 'YYYY-MM-DD HH:mm:ss'
    return (timestamp)
      ? moment.tz(timestamp * 1000, timezone).format(format)
      : moment(timestamp * 1000).format(format)
  },

  isInFuture(timestamp, timezone) {
    const cm = moment()
    const current_timestamp = cm.utc().valueOf()
    return (timestamp * 1000 > current_timestamp) ? true : false
  }


}

export default dateFormatter