import { FormState, FormGetters, FormMutations, FormActions } from '@/modules/abstracts/form'
import Api from '@/plugins/api'

const state = FormState({
  fields: {
    photo: null,
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    password_confirmation: null,
    country_id: '1',
    agreed: true,
    timezone: null,
  }
})
const mutations = FormMutations()
const getters = FormGetters({

  VALIDATES: (state) => {
    return {
      photo: ['required', 'Please upload your photo.'],
      firstname: ['required', 'Please enter your first name.'],
      lastname: ['required', 'Please enter your last name.'],
      email: ['required', 'Please enter your email.'],
      password: ['required', 'Please enter your password.'],
      password_confirmation: ['required|equals:password', ['Please repeat your password.', 'Passwords should match.']],
      timezone: ['required', 'Please select your timezone.'],
    }
  },

})
const actions = FormActions({
  async REGISTER({ dispatch, commit, state, rootState, getters }) {

    await dispatch('VALIDATE_FIELDS').catch(() => {
      //throw new Error('Registration form rejected [validationErrors]')
    })

    commit('auth/SET_LOADING', true, { root: true })

    Api.post('auth/register', getters.FIELDS_AS_FORM_DATA, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=reg'
      }
    })
    .then((response) => {
      const accessToken = response.data.token
      const data = response.data.user_data
      dispatch('auth/AUTHENTICATE', { accessToken, data, redirect: true }, { root: true })
    })
    .catch((response) => {
      commit('auth/SET_LOADING', false, { root: true })
      commit('MERGE_ERRORS', response.body.data)
    })
  }
})
//const getters = RepositoryGetters()

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
  // getters
}
