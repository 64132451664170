<template lang="pug">
  .view.view-register
    v-sheet(elevation="1" dark)
      .background-accent
      .title Register
        v-icon ic-person
      .small.mt-2 Register a FREE account and start witnesing the benefits of our system today!


      FilePond.avatar(
        name="photo"
        ref="pond"
        labelIdle="Drop your photo..."
        accepted-file-types="image/*"
        allowRevert="true"
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth="200"
        imageResizeTargetHeight="200"
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center bottom"
        styleProgressIndicatorPosition="right bottom"
        styleButtonRemoveItemPosition="left bottom"
        styleButtonProcessItemPosition="right bottom"
        :server="server"
        :onpreparefile="onInitFile"
      )
      .avatar-error(v-if="showPhotoError") {{ errors.photo }}


      v-text-field.mt-8(name="first_name" label="First name" :error-messages="errors.firstname" v-model="firstname" rounded filled)
      v-text-field(name="last_name" label="Last name" :error-messages="errors.lastname" v-model="lastname" rounded filled)

      v-text-field(name="email" label="Email Address" :error-messages="errors.email" v-model="email" rounded filled)
      v-text-field(name="password" label="Password" type="password" :error-messages="errors.password" v-model="password" rounded filled)
      v-text-field(name="password_confirmation" label="Repeat Password" type="password" :error-messages="errors.password_confirmation" v-model="password_confirmation" rounded filled)
      v-select(:items="timezones" label="Timezone" rounded filled v-model="timezone")

      div
        v-checkbox(v-model="termsCheckbox" color="primary" style="width:24px !important; margin-top:0px; padding-top:0px; float:left")
        div.terms(style="float:left")
          .wrap(style="position:absolute; z-index:999999")
            |  By clicking Register, I agree to the&nbsp;
            a(href="/terms-of-use" target="_blank" ) &nbsp;Terms of Service
            |  and&nbsp;
            router-link.primary--text(to="/privacy" target="_blank") Privacy Policy

      v-btn.submit.mt-5(color="#35543a" depressed=true hide-details x-large
        @click="register"
        :loading="isLoading"
        :disabled="true"
      ) Coming soon

    .bottom
      | Already have an account? 
      router-link.primary--text(to="login") Login Now
</template>

<script>
import FormMixin from '@/mixins/FormMixin'


import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import store from "../../store";


export default {

  components: {
    FilePond: vueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginImageCrop,
      FilePondPluginImageResize,
      FilePondPluginImageTransform,
      FilePondPluginImageEdit
    )
  },

  mixins: [
    FormMixin('registration')
  ],

  data() {
    return {
      termsCheckbox: false,
      avatarUploaded: false
    }
  },

  computed: {
    server() {
      return {
        /*url: process.env.VUE_APP_API + '/auth/register/avatar/',
        process: {
          url:  'upload',
          onload: this.handleProcessFile
        },
        revert: {
          url: 'delete',
          onload: this.handleDeleteFile
        },
        load: null,
        restore: null,
        fetch: null
        */
      }
    },
    registerActive() {
      return !this.termsCheckbox
    },
    isLoading() {
      return this.$store.state.auth.is_preloading
    },
    showPhotoError() {
      return this.errors.photo && this.errors.photo.length > 0 //&& !this.avatarUploaded
    },

    timezones() {
      return this.$store.state.timezones
    },
  },

  methods: {
    register() {
        this.formDispatch('REGISTER')
    },

    handleProcessFile(resp) {
      let model = JSON.parse(resp)
      this.avatarUploaded = true
      this.formDispatch('UPDATE_FIELD', {
        field: 'avatar',
        value: model.fullpath
      })
      return resp
    },

    handleDeleteFile(file) {
      this.avatarUploaded = false
      this.formDispatch('UPDATE_FIELD', {
        field: 'avatar',
        value: null
      })
    },

    enterDown(event) {
      if (event.keyCode == 13)
        this.register()
    },

    onInitFile(file, output) {
      this.photo = output
      //return;
      
      //Converting output to base64
      /*let reader = new FileReader()
      reader.readAsDataURL(output)

      const $this = this;

      reader.onload = function() {
        $this.photo = reader.result
      }
      */
    }
  },

  mounted() {
    window.addEventListener("keypress", this.enterDown);
  },

  destroyed() {
    window.removeEventListener("keypress", this.enterDown);
  }
}
</script>