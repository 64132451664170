/**
 *  ALL the results must be as Boolean expression
 *  and must be returned inversely
 *  where: FALSE - qualifies validator, TRUE - rejects it
 *
 *  Every validator can have 3 input params as fallowing (ordering matters)
 *    @param {*} value - form/field value which requires this validation.
 *    @param {Array} params - validator paramters distinct by ':' symbol
 *    @param {Object} fields - all the form fields for some cases

#################################################################
#################################################################
 */

/**
*   Qualifies required
*   usage: email: ['required', 'Please enter your email.']
*/
export function required(value) {
  if (typeof value === 'undefined' ||
      value === null ||
      value === ''
    ) {
    return true
  }
  return false
}

/**
*  Checks equality (inversely) for value with a given field name,
    which stores as first item in params Array

  usage: password_repeat: ['equals:password', 'Passwords should match.']
*/
export function equals(value, params, fields) {
  return value !== fields[params[0]]
}